import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'node-fetch';

const defaultHeaders = {
  Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
};

const httpLink = createHttpLink({
  uri: process.env.GATSBY_API_URL,
  options: { mode: 'no-cors', fetchPolicy: 'no-cache' },
  fetch, // Resolving an SSR issue: https://stackoverflow.com/questions/64362315/gatsby-webpackerror-invariant-violation-error-with-apolloclient
});

const wsLink =
  typeof window !== 'undefined'
    ? new WebSocketLink({
        uri: process.env.GATSBY_SUBSCRIPTION_URL,
        options: {
          reconnect: true,
          mode: 'no-cors',
          connectionParams: async () => defaultHeaders,
        },
      })
    : null;

const splitLink =
  typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    ...defaultHeaders,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) =>
      console.log(`[GraphQL Error]: ${message}`)
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.from([authLink, errorLink, splitLink]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export default new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions,
});
