import { Loader } from '@googlemaps/js-api-loader';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useMeetings from './useMeetings';

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

const AppStateContext = createContext({});

export function AppStateProvider({ children }) {
  const intervalRef = useRef();
  const [sessionStartTime] = useState(dayjs.tz());
  const [currentTime, setCurrentTime] = useState(dayjs.tz());
  const meetings = useMeetings({
    firstRaceFrom: sessionStartTime.startOf('day'),
    to: sessionStartTime.endOf('day'),
  });
  const [nextToJump, setNextToJump] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    async function init() {
      const loader = new Loader({
        apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['geometry'],
      });

      await loader.load();

      setReady(true);
    }

    init();
  }, []);

  useEffect(() => {
    function updateTime() {
      setCurrentTime(dayjs.tz());
    }

    intervalRef.current = setInterval(updateTime, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    setNextToJump(
      meetings
        ?.reduce(
          (aggregated, meeting) => [
            ...aggregated,
            ...meeting.races
              .filter((race) => race.status === 'OPEN')
              .map((race) => ({
                ...race,
                meeting: {
                  id: meeting.id,
                  type: meeting.type,
                  track: meeting.track,
                  externalId: meeting.externalIDs?.[0].id,
                },
              })),
          ],
          []
        )
        ?.sort((a, b) => dayjs.tz(a.startTime) - dayjs.tz(b.startTime))
        .slice(0, 3)
    );
  }, [meetings]);

  if (!ready) return null;

  return (
    <AppStateContext.Provider
      value={{
        currentTime,
        nextToJump,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useAppState() {
  const appState = useContext(AppStateContext);
  return appState;
}
